import { createRouter, createWebHistory } from 'vue-router'
//import store from '../store.js'
//import Home from '../views/Home.vue'
import BankAccounts from '../views/BankAccounts/BankAccounts'
import Register from '../views/Register'
import axios from "axios";

import config from '../services/config';


const routes = [
    {
        path:'/',
        redirect:'/bc'
    },

  {
    path: '/bc',
    name: 'BankingCenter',
    component: () => import('../views/BankingCenter/Home'),
    meta: {
      //requiresAuth: true
    }
  },

    {
        path: '/bc/member',
        name: 'Member',
        component: () => import('../views/BankingCenter/Member')
    },

    {
        path: '/bc/recent',
        name: 'Recent',
        component: () => import('../views/BankingCenter/Last30days')
    },
    {
        path: '/bc/upcoming',
        name: 'Upcoming',
        component: () => import('../views/BankingCenter/Upcoming')
    },

    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login.vue')
    },
    {
        path: '/register',
        name: 'Register',
        component: Register
    },
    {
        path: '/verifyemail',
        name: 'VerifyEmail',
        component: () => import('../views/VerifyEmail')
    },


    {
        path: '/settings/family',
        name: 'Family',
        component: () => import('../views/Family/Family.vue')
    },
    {
        path: '/settings/familyprofile',
        name: 'FamilyProfile',
        component: () => import('../views/Family/FamilyProfile')
    },
    {
        path: '/settings/family/user',
        name: 'FamilyUser',
        component: () => import('../views/Family/FamilyUser.vue')
    },
    {
        path: '/settings/bas',
        name: 'BankAccounts',
        component: BankAccounts,
    },
    {
        path: '/settings/bas/ba',
        name: 'BankAccount',
        component: () => import('../views/BankAccounts/BankAccount')
    },
  {
    path: '/settings/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
    {
        path: '/accounts/transactions',
        name: 'Transactions',
        component: () => import('../views/ActualTrans')
    },

    {
        path: '/budget',
        name: 'BudgetHome',
        component: () => import('../views/Budget/BudgetHome')
    },
    {
        path: '/tran/new',
        name: 'NewTran',
        component: () => import('../views/Tran/NewTran')
    },
    {
        path: '/tran/edit',
        name: 'EditTran',
        component: () => import('../views/Tran/EditTran')
    },
    {
        path: '/tran/convert',
        name: 'ConvertTran',
        component: () => import('../views/Tran/ConvertTran')
    },

    {
        path: '/insights',
        name: 'Insights',
        component: () => import('../views/Insights/Insights')
    },

    {
        path: '/insights/books',
        name: 'Books',
        component: () => import('../views/Insights/List/Books')
    },

    {
        path: '/insights/articles',
        name: 'Articles',
        component: () => import('../views/Insights/List/Articles')
    },

    {
        path: '/insights/courses',
        name: 'Courses',
        component: () => import('../views/Insights/List/Courses')
    },

    {
        path: '/insights/parenting',
        name: 'Parenting',
        component: () => import('../views/Insights/List/Parenting')
    },

    {
        path: '/insights/tools',
        name: 'Tools',
        component: () => import('../views/Insights/List/Tools')
    },

    {
        path: '/insights/links',
        name: 'Links',
        component: () => import('../views/Insights/List/Links')
    },

    {
        path: '/trans',
        name: 'Trans',
        component: () => import('../views/Trans')
    },

    {
        path: '/share',
        name: 'ShareTran',
        component: () => import('../views/ShareTran')
    },

    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings')
    },
    {
        path: '/passwordreset',
        name: 'PasswordReset',
        component: () => import('../views/PasswordReset')
    },
    {
        path: '/activateaccount',
        name: 'ActivateAccount',
        component: () => import('../views/ActivateAccount')
    },
    {
        path: '/settings/passwordchange',
        name: 'PasswordChange',
        component: () => import('../views/PasswordChange')
    },

    {
        path: '/noaccess',
        name: 'Noaccess',
        component: () => import('../views/Noaccess')
    },
    {
        path: '/outdated',
        name: 'Outdated',
        component: () => import('../views/Outdated')
    },
    {
        path: '/settings/extenduser',
        name: 'ExtendUser',
        component: () => import('../views/ExtendUser')
    },
    {
        path: '/settings/faqs',
        name: 'FAQs',
        component: () => import('../views/FAQs')
    },
    {
        path: '/settings/invite',
        name: 'InviteOthers',
        component: () => import('../views/InviteOthers')
    },
    {
        path: '/settings/contactus',
        name: 'ContactUs',
        component: () => import('../views/ContactUs')
    },
    {
        path: '/settings/family/savinggoal',
        name: 'SavingGoal',
        component: () => import('../views/Family/SavingGoal')
    },
    {
        path: '/terms-and-conditions',
        name: 'TermsOfUse',
        component: () => import('../views/TermOfUse')
    },
    {
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: () => import('../views/PrivacyPolicy')
    },
    {
        path: '/community-rules',
        name: 'CommunityRules',
        component: () => import('../views/CommunityRules')
    },
    {
        path: '/test/test1',
        name: 'Test1',
        component: () => import('../views/Test/test1')
    },
    {
        path: '/test/test2',
        name: 'Test2',
        component: () => import('../views/Test/test2')
    },
    {
        path: '/test/time',
        name: 'TestTime',
        component: () => import('../views/Test/time')
    },
    {
        path: '/test/async',
        name: 'Async',
        component: () => import('../views/Test/async')
    }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,

})

router.beforeEach((to, from, next) => {
    const authRequired = !config.publicPages.includes(to.path);
    let loggedIn = localStorage.getItem('user');

    const adminRequired = config.adminOnlyPages.includes(to.path);
    //console.log(loggedIn);

    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    }else if (authRequired && loggedIn) {
        axios
            .post(config.hostApi, {
                task:'tokenAuth',
                userAgent:navigator.userAgent
            },{
                headers:{
                    'token':loggedIn
                }
            })
            .then(response => {
                //console.log(response.data);
                let userInfo = response.data.userInfo;


                if(userInfo && userInfo.MIN_VERSION_REQUIRED > config.version && to.path !== '/outdated'){
                    next('/outdated');
                }else if(userInfo && userInfo.FAMILY_ADMIN !== '1' && adminRequired){
                    next('/noaccess');
                }else{
                    next();
                }
            }).catch(error => {
                localStorage.clear();
                next('/login');
            });

    } else {
        next();
    }
});

export default router
