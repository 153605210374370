import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import {
    faHome,
    faUser,
    faUserPlus,
    faSignInAlt,
    faSignOutAlt,
    faUsers,
    faShoppingBag,
    faCog,
    faCaretRight,
    faCaretDown,
    faPlusCircle,
    faMinusCircle,
    faExchangeAlt,
    faHeart,
    faPowerOff,
    faLightbulb,
    faCircle,
    faPencilAlt,
    faTrash,
    faUniversity,
    faPlus,
    faTimes,
    faArrowLeft,
    faKey,
    faFileInvoiceDollar
    ,faMoneyCheckAlt
    ,faDollarSign
    ,faCalendarDay
    ,faCheck
    ,faExclamationTriangle
    ,faExclamationCircle
    ,faChartLine
    ,faInfoCircle
    ,faCertificate
    ,faQuestion
    ,faBullhorn
    ,faDotCircle
    ,faCommentDots
    ,faPaperPlane
    ,faBullseye
    ,faMoneyCheck
    ,faFeather
    ,faPiggyBank
    ,faGift
    ,faArrowRight
    ,faLongArrowAltUp
    ,faLongArrowAltDown
    ,faToggleOn
    ,faToggleOff
    ,faMehBlank
    ,faCoins
    ,faShare
    ,faThumbsUp
    ,faSave
    ,faHistory
    ,faClock

} from "@fortawesome/free-solid-svg-icons";


library.add(faHome,faUsers,faShoppingBag,faCog,faCaretRight,faCaretDown,faPlusCircle,faMinusCircle, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faExchangeAlt, faHeart, faPowerOff, faLightbulb, faCircle,faPencilAlt,
    faTrash,
    faUniversity,
    faPlus
,faTimes
,faArrowLeft
    ,faKey
    ,faFileInvoiceDollar
    ,faMoneyCheckAlt
    ,faDollarSign
    ,faCalendarDay
    ,faCheck
    ,faExclamationTriangle
    ,faExclamationCircle
    ,faChartLine
    ,faInfoCircle
    ,faCertificate
    ,faQuestion
    ,faBullhorn
    ,faDotCircle
    ,faCommentDots
    ,faPaperPlane
    ,faBullseye
    ,faMoneyCheck
    ,faFeather
    ,faPiggyBank
    ,faGift
    ,faArrowRight
    ,faLongArrowAltUp
    ,faLongArrowAltDown
    ,faToggleOn
    ,faToggleOff
    ,faMehBlank
    ,faCoins
    ,faShare
    ,faThumbsUp
    ,faSave
    ,faHistory
    ,faClock

);


export { FontAwesomeIcon };
