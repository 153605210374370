<template>
  <div id="backArrow" class="backBtn clickable" @click="goBack()"><font-awesome-icon :icon="['fas', 'arrow-left']" /></div>
</template>

<script>

export default {
  name: 'BackButton',
  props: {
    path: {}
  },
  data(){
    return{

    }
  },

  methods:{

    goBack:function(){

      if(this.path){
        this.$root.gotoRoute(this.path);
      }else{
        this.$root.playClickSound();
        this.$router.go(-1);
      }

    },



  }

}
</script>

<style scoped>


</style>
