<template>
  <div class="mainContainer container" style="">
    <back-button />
    <div id="logoDiv"><img src="../assets/logo.svg"></div>

    <div class="row" v-if="scrCheckParent">
      <div class="col-md-12 isParentDiv">
        <h5>Are you a parent or a guardian and at least 18 years old?</h5>
        <div class="parentBtns">
          <button @click="showRegForm" class="btn btn-success">Yes</button>
          <button @click="isParent = false"  class="btn btn-danger">No</button>
        </div>
      </div>
    </div>

    <div class="row " v-if="!isParent">
      <div class="col-md-12 isParentDiv notParentMsg">
        <div>Sorry, only an adult can register this service. Please ask your parent or guardian for help.</div>
      </div>
    </div>

    <div class="row" v-if="scrRegForm">
      <div class="col-md-12">

        <div class="card-container">
          <form @submit.prevent="handleRegister">
            <div v-if="!successful">
              <div class="form-group">
                <label for="parentRole">My Parental Role</label>
                <select name="parentRole" v-model="parentRole" class="form-control" @change="validateParentRole()">
                  <option value="">- Select One -</option>
                  <option value="Mom">Mom</option>
                  <option value="Dad">Dad</option>
                  <option value="Guardian">Guardian</option>
                </select>
                <ul class="error-feedback" v-if="parentRoleErrors.length > 0" v-html="parentRoleErrors"></ul>
              </div>
              <div class="form-group">
                <label for="accountname">My First Name or Nick Name</label>
                <input name="accountname" type="text" class="form-control"  v-model="accountName"  @change="validateAccountName()"/>
                <ul class="error-feedback" v-if="accountNameErrors.length > 0" v-html="accountNameErrors"></ul>
              </div>
              <div class="form-group">
                <label for="email">Email (used for login)</label>
                <input name="email" type="email" class="form-control"  v-model="email"  @change="validateEmail()" />
                <ul class="error-feedback" v-if="emailErrors.length > 0" v-html="emailErrors"></ul>
              </div>
              <div class="form-group">
                <label for="password">Password</label>
                <input name="password" type="password" class="form-control" autocomplete="new-password" v-model="password"  @change="validatePassword()"/>
                <ul class="error-feedback" v-if="passwordErrors.length > 0" v-html="passwordErrors"></ul>
              </div>

              <div class="form-group">
                <label for="passwordConfirmation">Confirm Password </label>
                <input id="passwordConfirmation" name="passwordConfirmation" type="password"  class="form-control" autocomplete="new-password" v-model="passwordConfirmation"  @change="validatePasswordConfirmation()"/>
                <ul class="error-feedback" v-if="passwordConfirmationErrors.length > 0" v-html="passwordConfirmationErrors"></ul>
              </div>

              <div class="form-group">
                <label for="familyname">My Family Name (e.g. the Fantastic Four)</label>
                <input name="familyname" type="text" class="form-control" v-model="familyName"  @change="validateFamilyName()"/>
                <ul class="error-feedback" v-if="familyNameErrors.length > 0" v-html="familyNameErrors"></ul>
              </div>

              <div class="form-group">
                <label for="country">Country</label>
                <select class="form-control" id="country" v-model="country" @change="getCountry($event,$event.target.selectedIndex)">
                  <option value="">- Select One -</option>
                  <option v-for="(country,idx) in countryRegionData" :key="idx" :value="country.countryName">{{country.countryName}}</option>
                </select>
                <ul class="error-feedback" v-if="countryErrors.length > 0" v-html="countryErrors"></ul>
              </div>

              <div class="form-group">
                <label for="region">State/Province/Region</label>
                <select class="form-control" id="region" v-model="region" @change="validateRegion()">
                  <option value="">- Select One -</option>
                  <option v-for="(region,idx) in countryRegionData[countryIdx].regions" :key="idx" :value="region.name">{{region.name}}</option>
                </select>
                <ul class="error-feedback" v-if="regionErrors.length > 0" v-html="regionErrors"></ul>
              </div>

              <div class="form-group">
                <label for="currency">Currency</label>
                <select class="form-control" id="currency" v-model="currency" @change="validateCurrency()">
                  <option value="">- Select One -</option>
                  <option v-for="(currency,idx) in currencies" :key="idx" :value="currency.cc">{{currency.cc}} - {{currency.name}} - {{currency.symbol}}</option>
                </select>
                <ul class="error-feedback" v-if="currencyErrors.length > 0" v-html="currencyErrors"></ul>
              </div>

              <div class="form-group row agreeDiv">
                <div class="col-sm-2">
                  <input type="checkbox" id="agreed" v-model="agreed" />
                </div>
                <div class="col-sm-10">
                  <label class="col-sm-10 col-form-label" id="touLabel" for="checkbox">I agree to Goblin Bank's <span class="clickable alink" @click="this.$root.gotoRoute('/terms-and-conditions')">Terms of Use</span> and the <span class="clickable alink" @click="this.$root.gotoRoute('/privacy-policy')">Privacy Policy</span></label>
                </div>

                <ul class="error-feedback" v-if="agreedErrors.length > 0" v-html="agreedErrors"></ul>
              </div>


              <div class="form-group">
                <button class="btn btn-primary btn-block" :disabled="loading">
                  <span
                      v-show="loading"
                      class="spinner-border spinner-border-sm"
                  ></span>
                  Sign Up
                </button>
              </div>
            </div>
          </form>



          <div
              v-if="message"
              class="alert"
              :class="successful ? 'alert-success' : 'alert-danger'" v-html="message"
          >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryRegionData from "@/services/countryregion.json";
import currencies from "@/services/currencies.json";


export default {
  name: "Register",
  components: {


  },
  data() {
    return {
      successful: false,
      loading: false,
      message: "",
      isParent:true,
      scrCheckParent:true,
      scrNotParent:false,
      scrRegForm:false,
      countryIdx:0,
      regionIdx:0,
      countryRegionData:countryRegionData,
      currencies:currencies,
      currency:'',
      currencyErrors:'',
      hasValidationError: false,
      parentRole:'',
      parentRoleErrors:'',
      accountName:'',
      accountNameErrors:'',
      email:'',
      emailErrors:'',
      password:'',
      passwordErrors:'',
      passwordConfirmation:'',
      passwordConfirmationErrors:'',
      familyName:'',
      familyNameErrors:'',
      country:'',
      countryErrors:'',
      region:'',
      regionErrors:'',
      agreed:false,
      agreedErrors:'',
      formData:{}
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    }
  },
  mounted() {

    document.title = "Sign Up";
    if (this.loggedIn) {
      this.$root.gotoRoute("/");
    }
  },
  methods: {
    getCountry: function(e,idx){
      this.countryIdx = idx-1;
      this.validateCountry();
    },

    handleRegister() {

      this.$root.playClickSound();

      this.formData = {
        'parentalRole':this.parentRole,
        'accountName':this.accountName,
        'email':this.email,
        'password':this.password,
        'passwordConfirmation':this.passwordConfirmation,
        'familyName':this.familyName,
        'country':this.country,
        'region':this.region,
        'currency':this.currency,
        'agreed':this.agreed
      };

      //console.log(this.formData);

      this.hasValidationError = false;
      this.validateAll();
      if(this.hasValidationError) return;

      this.message = "";
      this.successful = false;
      this.loading = true;



      this.$store.dispatch("auth/register", this.formData).then(
          (data) => {
            if(data === 'Account exists'){
              this.message = "This email already has a registered account. Please <a href='/login'>log in</a>.";
              this.successful =false
            }else if(data === 'added'){
              this.message = "Your account and family have been created. A verification code has been sent to your email. Please grab that code and then <a href='/login'>log in</a>.";
              this.successful = true;
            }else{
              this.message = data;
              this.successful = true;
            }

            this.loading = false;
            //console.log(data);


          },
          (error) => {
            this.message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            //this.message = JSON.stringify(error) || error;
            this.successful = false;
            this.loading = false;
          }
      );
    },

    showRegForm(){
      this.scrRegForm = true;
      this.scrCheckParent = false;
      this.isParent = true;
    },

    validateEmail() {
      //console.log('email is '+this.email);
      let errors = (/\S+@\S+\.\S+/.test(this.email)) ? '' : '<li>email is not valid</li>';
      errors += this.$root.validateLength(this.email,0,100);
      this.emailErrors = errors;
      if(this.emailErrors.length > 0){
        this.hasValidationError = true;
      }
    },


    validateParentRole(){
      this.parentRoleErrors =this.$root.validateLength(this.parentRole,0,8);
      if(this.parentRoleErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateAccountName(){
      this.accountNameErrors = this.$root.validateLength(this.accountName,3,20);
      if(this.accountNameErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePassword(){
      this.passwordErrors = this.$root.validateLength(this.password,6,20);
      if(this.passwordErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validatePasswordConfirmation(){
      this.passwordConfirmationErrors = this.$root.validateLength(this.passwordConfirmation,6,20);
      if(this.passwordConfirmationErrors.length > 0){
        this.hasValidationError = true;
      }

      if(this.passwordConfirmation !== this.password){
        this.passwordConfirmationErrors += "<li>passwords don't match</li>";
        this.hasValidationError = true;
      }
    },

    validateFamilyName(){
      this.familyNameErrors = this.$root.validateLength(this.familyName,3,20);
      if(this.familyNameErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateCountry(){
      this.countryErrors = this.$root.validateLength(this.country,0,100);
      if(this.countryErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateRegion(){
      //console.log('region is '+this.region);
      this.regionErrors = this.$root.validateLength(this.region,0,100);
      if(this.regionErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateCurrency(){
      //console.log('region is '+this.region);
      this.currencyErrors = this.$root.validateLength(this.currency,0,100);
      if(this.currencyErrors.length > 0){
        this.hasValidationError = true;
      }
    },

    validateAgreed(){
      this.agreedErrors = '';
      if(!this.agreed) this.agreedErrors = "<li>You have to agree to our Terms of Use and our Privacy Policy in order to use our service.</li>";
      if(this.agreedErrors.length > 0){
        this.hasValidationError = true;
      }
    },



    validateAll(){
      this.validateParentRole();
      this.validateAccountName();
      this.validateEmail();
      this.validatePassword();
      this.validatePasswordConfirmation();
      this.validateFamilyName();
      this.validateCountry();
      this.validateRegion();
      this.validateCurrency();
      this.validateAgreed();
    }

  },
};
</script>

<style scoped>

#logoDiv{
  margin:0 auto 30px auto;
  padding:10px 20px;
  text-align: center;
}

#logoDiv img{
  width:200px;
}


.error-feedback{
  font-size:10px;
  color:red;

}

.error-feedback-div{
  margin-bottom:5px;
}

.form-group{
  margin:20px;
}

.parentBtns{
  margin:30px;
}

.parentBtns button{
  margin:10px 20px;
  width:100px;
}

.isParentDiv{
  text-align: center;
}

.notParentMsg{
  margin-top:50px;
}

select option{
  font-size:12px;
  width:100%;
}

#agreed{
  margin-right:10px;
}

#touLabel{
  font-size:12px;
}

.agreeDiv{
  background-color: #e7e8e8;
  border-radius: 2px;
  padding:10px 0;
}

.col-sm-2{
  width:15%;
}

.col-sm-10{
  width:85%;
}

</style>
