/*export default {
    hostname: 'http://localhost:55',
    hostApi: 'http://localhost:55/api/',
    minV:2,
    warningDays:30,
}*/


export default {
    hostname: 'https://api.goblinbank.com',
    hostApi: 'https://api.goblinbank.com/api4/',
    /*hostname: 'http://localhost:888',
    hostApi: 'http://localhost:888/api4/',*/
    version:28,
    versionName:'4.1',
    userLimit:10,
    singleUserAccountLimit:7,
    dailyTransactionLimit:100,
    scheduleLimit:30,
    noBottomNavPages:[
        //route names
        'Login',
        'Register',
        'ActivateAccount',
        'Outdated',
        'PasswordReset',
        'TermsOfUse',
        'PrivacyPolicy',
        'VerifyEmail',

    ],
    publicPages:[
        '/login',
        '/register',
        '/passwordreset',
        '/activateaccount',
        '/terms-and-conditions',
        '/privacy-policy',
        '/outdated'
    ],
    adminOnlyPages:[
        '/settings/family',
        '/settings/bas',
        '/settings/familyprofile',
        '/settings/bas/ba',
        '/settings/family/user',
        '/settings/bas/ba',

    ]
}
