import axios from 'axios';
import config from './config';

const API_URL = config.hostApi;
const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
var d = new Date();
const timezoneOffset = d.getTimezoneOffset();


class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'login/', {
                username: user.username,
                password: user.password,
                userAgent: user.userAgent,
                version:config.version
            })
            .then(response => {
                //console.log(response.data);
                if (response.data) {

                    localStorage.clear();
                    localStorage.setItem('user', response.data.jwt);
                    localStorage.setItem('userInfo', JSON.stringify(response.data.userInfo));
                    localStorage.setItem('familyInfo', JSON.stringify(response.data.familyInfo));
                    localStorage.setItem('familyUsers', JSON.stringify(response.data.familyUsers));


                }

                return response.data;
            });
    }


    logout() {
        localStorage.clear();
    }

    register(user) {
        return axios.post(API_URL + 'signup/', {
            parentalRole: user.parentalRole,
            fullName: user.accountName,
            familyName: user.familyName,
            email: user.email,
            password: user.password,
            country: user.country,
            region: user.region,
            currency:user.currency,
            localTimezone:localTimeZone,
            timezoneOffset:timezoneOffset,
            version:config.version,
            agreed:user.agreed ? '1' : '0'
        });
    }
}

export default new AuthService();
