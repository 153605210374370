<template>
  <div id="topbar">

  </div>
  <div id="nav" v-if="showMenu">
    <AppLink class="clickable" to="/bc"><div><font-awesome-icon :icon="['fas', 'coins']" /></div><div class="topNavTxt">Banking Center</div></AppLink>
    <AppLink class="clickable" to="/settings"><div><font-awesome-icon :icon="['fas', 'cog']" /></div><div class="topNavTxt">Settings</div></AppLink>

  </div>
  <router-view/>


</template>


<script>
import AppLink from "@/components/AppLink";
import axios from "axios";
import {App as CapacitorApp} from "@capacitor/app";
import config from "@/services/config";
import {colors} from "@/services/colors";
import { datetime, RRule, RRuleSet, rruleDb } from 'rrule'





export default {
  components: {
    AppLink,

  },
  data() {
    return {
      touchstartX:0,
      touchstartY:0,
      touchendX:0,
      touchendY:0,

      me:{},

      periodOptions: [
        {id:0, name:'Now',months:0,description:''},
        {id:1, name:'1M',months:1,description:'in 1 Month'},
        {id:2, name:'3M',months:3,description:'in 3 Months'},
        {id:3, name:'6M',months:6,description:'in 6 Months'},
        {id:4, name:'1Y',months:12,description:'in 1 Year'},
        {id:5, name:'5Y',months:60,description:'in 5 Years'},
      ],
      metaLoaded:{
        balances:'0',
        schedules:'0',
        familyBas:'0',
        familyUsers:'0',
        familyInfo:'0',
        userInfo:'0',
        balanceChartData:'0',
        futureBalances:'0'
      },
      localTimeZone: null,
      timezoneOffset: null,
      refreshNeeded:{
        balance: false,
        metaData: false
      }
    }
  },
  computed:{


    showMenu() {
      return !this.$config.noBottomNavPages.includes(this.$route.name);
    },

  },

  methods: {
    getRefreshSignals: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'getRefreshSignals'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      return await response.data;
    },

    checkRefreshSignals: async function(){
      let newRefreshSignals = await this.getRefreshSignals();
      let oldRefreshSignals = JSON.parse(localStorage.getItem('refreshSignals'));

      let balanceChartData = JSON.parse(localStorage.getItem('balanceChartData') );
      let schedules = JSON.parse(localStorage.getItem('schedules'));
      let familyBas = JSON.parse(localStorage.getItem('familyBas'));
      let familyInfo = JSON.parse(localStorage.getItem('familyInfo'));
      let familyUsers = JSON.parse(localStorage.getItem('familyUsers'));

      if(oldRefreshSignals && !this.isLater(newRefreshSignals.BALANCE_REFRESH_SIGNAL, oldRefreshSignals.BALANCE_REFRESH_SIGNAL) && schedules && familyBas && balanceChartData){
        this.refreshNeeded.balance = false;
      }else{
        this.refreshNeeded.balance = true;
      }

      if(oldRefreshSignals && !this.isLater(newRefreshSignals.META_DATA_REFRESH_SIGNAL, oldRefreshSignals.META_DATA_REFRESH_SIGNAL) && familyInfo && familyUsers){
        this.refreshNeeded.metaData = false;
      }else{
        this.refreshNeeded.metaData = true;
      }

      return newRefreshSignals;
    },


    isLater(newDate, oldDate){
      if(!oldDate) oldDate = new Date();
      //console.log(Date.parse(newDate));
      //console.log(Date.parse(oldDate));
      return Date.parse(newDate) > Date.parse(oldDate);
    },

    doRefresh: async function(){
      //console.log('doRefresh run');
      let refreshSignals = await this.checkRefreshSignals();
      let refreshed = false;
      if(this.refreshNeeded.balance){
        await this.refreshLocalSchedules2();
        await this.refreshLocalBas2();
        refreshed = true;
      }

      if(this.refreshNeeded.metaData){
        await this.refreshLocalFamilyInfo2();
        await this.refreshLocalFamilyUsers2();
        refreshed = true;
      }

      if(refreshed){
        var schedules = JSON.parse(localStorage.getItem('schedules'));
        var allUpcomingTrans = this.getAllUpcomingTrans(schedules);
        this.createBalanceChartData(schedules, allUpcomingTrans);
        this.createFutureBalances(this.periodOptions, schedules, allUpcomingTrans);
      }

      localStorage.setItem('refreshSignals', JSON.stringify(refreshSignals));

      //console.log('doRefresh end');

      return refreshed;

    },


    getBalanceRefreshSignal: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'getBalanceRefreshSignal'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let balanceRefreshSignal = await response.data;
      //console.log(schedules);

      localStorage.setItem('balanceRefreshSignal', JSON.stringify(balanceRefreshSignal));
      return balanceRefreshSignal;
    },

    turnOffBalanceRefreshSignal: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'turnOffBalanceRefreshSignal'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let balanceRefreshSignal = await response.data;
      //console.log(schedules);

      localStorage.setItem('balanceRefreshSignal', JSON.stringify(balanceRefreshSignal));
      return balanceRefreshSignal;
    },

    refreshLocalUserInfo: async function(){
      this.me = this.getLocalUserInfo();
      return this.axios
          .post(this.$hostApi,{task:'getUser', userId:this.me.ID},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            localStorage.setItem('userInfo', JSON.stringify(response.data));
            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },

    refreshLocalFamilyInfo: async function(){
      return this.axios
          .post(this.$hostApi,{task:'getFamily'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            localStorage.setItem('familyInfo', JSON.stringify(response.data));
            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },

    refreshLocalFamilyInfo2: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'getFamily'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let familyInfo = await response.data;

      localStorage.setItem('familyInfo', JSON.stringify(familyInfo));
      //console.log('refreshLocalFamilyInfo2 run');
      return familyInfo;
    },

    refreshLocalFamilyUsers: async function(){
      this.me = this.getLocalUserInfo();
      return this.axios
          .post(this.$hostApi,{task:'getFamilyUsers'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            var familyUsers = response.data;
            localStorage.setItem('familyUsers', JSON.stringify(familyUsers));

            var userInfo = familyUsers.filter(user=>user.ID === this.me.ID)[0];
            localStorage.setItem('userInfo', JSON.stringify(userInfo));

            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },

    refreshLocalFamilyUsers2: async function(){

      let response = await this.axios
          .post(this.$hostApi,{task:'getFamilyUsers'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let familyUsers = await response.data;

      localStorage.setItem('familyUsers', JSON.stringify(familyUsers));
      let userInfo = familyUsers.filter(user=>user.ID === this.me.ID)[0];
      localStorage.setItem('userInfo', JSON.stringify(userInfo));
      //console.log('refreshLocalFamilyUsers2 run');
      return familyUsers;
    },

    refreshLocalBas: async function(){
      return this.axios
          .post(this.$hostApi,{task:'getBalances4'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            localStorage.setItem('familyBas', JSON.stringify(response.data));
            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },

    refreshLocalBas2: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'getBalances4'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let familyBas = await response.data;

      localStorage.setItem('familyBas', JSON.stringify(familyBas));

      //console.log('refreshLocalBas2 run');
      return familyBas;
    },

    refreshLocalSchedules: async function(){
      return this.axios
          .post(this.$hostApi,{task:'getSchedules'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            localStorage.setItem('schedules', JSON.stringify(response.data));
            //this.createBalanceChartData(response.data);
            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },


    refreshLocalSchedules2: async function(){
      let response = await this.axios
          .post(this.$hostApi,{task:'getSchedules'},{
            headers:{
              'token':localStorage.getItem('user')
            }
          });

      let schedules = await response.data;
      //console.log(schedules);

      localStorage.setItem('schedules', JSON.stringify(schedules));
      //console.log('refreshLocalSchedules2 run');
      return 1;
    },



/*
    refreshLocalMetaData: async function(){
      this.me = this.getLocalUserInfo();
      return this.axios
          .post(this.$hostApi,{task:'getAllMetaData', userId:this.me.ID},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            localStorage.setItem('userInfo', JSON.stringify(response.data.userInfo));
            localStorage.setItem('familyInfo', JSON.stringify(response.data.familyInfo));
            localStorage.setItem('familyUsers', JSON.stringify(response.data.familyUsers));
            return 1;
          })
          .catch(error => {
            console.error("There was an error!", error);
          })
    },
*/



    getLocalUserInfo: function(){
      return JSON.parse(localStorage.getItem('userInfo'));
    },

    getLocalFamilyInfo: function(){
      return JSON.parse(localStorage.getItem('familyInfo'));
    },

    getLocalSchedules: function(){
      return JSON.parse(localStorage.getItem('schedules'));
    },

    getLocalBas: function(){
      return JSON.parse(localStorage.getItem('familyBas'));
    },

    getLocalFamilyUsers: function(){
      return JSON.parse(localStorage.getItem('familyUsers'));
    },


    handleGesture: function () {
      if (this.touchendX < this.touchstartX) {
        //console.log('swipe left!');
      }
      if (this.touchendX > this.touchstartX) {
        //console.log('swipe right!');
        if(this.touchstartX < 10){
          this.$router.go(-1);
        }
      }
      if (this.touchendY < this.touchstartY) {
        //alert(swiped + 'down!');
      }
      if (this.touchendY > this.touchstartY) {
        //alert(swiped + 'up!');
      }
      if (this.touchendY === this.touchstartY) {
        //alert('tap!');
      }
      //console.log('touchstartX ' + this.touchstartX);
      //console.log('touchendX ' + this.touchendX);
      //console.log('touchstartY ' + this.touchstartY);
      //console.log('touchendY ' + this.touchendY);

    },

    playClickSound:function(){
      var familyInfo = this.getLocalFamilyInfo();
      //console.log(familyInfo.CLICK_SOUND);
      if(familyInfo && familyInfo.CLICK_SOUND === '1'){
        this.$clickSound.play();
      }
    },

    gotoRoute:function(route){
      this.playClickSound();
      this.$router.push(route);
    },

    validateLength(a,minl,maxl){
      a = a || '';
      a = a.toString().trim();
      return a && a.length >= minl ? (a.length <= maxl ? '' : '<li>must be less than ' + maxl + ' characters</li>') : (minl === 0 ? '<li>field is required</li>' : '<li>min ' + minl + ' character(s) are required</li>');
    },




    createBalanceChartData(schedules, allUpcomingTrans = null){
      if(!schedules){
        schedules = JSON.parse(localStorage.getItem('schedules'));
      }

      var d = new Date();
      d.setMonth(d.getMonth() + 12);
      //var fd = d.toLocaleDateString('en-CA', this.localTimeZone);
      var fd = this.formatDateYyyymmddLocal(d);
      if(!allUpcomingTrans){
        allUpcomingTrans = this.getAllUpcomingTrans(schedules);
      }
      var trans = allUpcomingTrans.filter(tran=>tran.TRAN_DATE <= fd);

      var totalByUserYearMonth = trans.reduce(function(res, tran) {
        var tranYearMonth = tran.TRAN_DATE.substring(0,7);
        var key = tran.USER_ID + '-' + tranYearMonth;
        //var tranDate = new Date(tran.date);
        //var tranYear = tranDate.getFullYear();
        //var tranMonth = tranDate.getMonth();


        if (!res[key]) {
          res[key] = {userId:tran.USER_ID, amount: 0, tranYearMonth:tranYearMonth};
          //totalByUserYearMonth.push(res[key]);
        }
        res[key].amount += parseFloat(tran.AMOUNT);
        return res;
      }, {});

      //console.log(totalByUserYearMonth);


      var yearMonths = [];
      yearMonths.push('1900-01');

      for(var i=0;i<12;i++){
        var d2 = new Date();
        d2.setMonth(d2.getMonth() + i);
        //console.log(d2);
        var year = d2.toLocaleString("default", { year: "numeric" });
        var month = d2.toLocaleString("default", { month: "2-digit" });
        yearMonths.push(year + '-' + month);
      }

      //console.log(yearMonths);



      var balanceData = [];
      var familyUsers = JSON.parse(localStorage.getItem('familyUsers'));
      var familyBas = JSON.parse(localStorage.getItem('familyBas'));
      var balanceInitialUsers = familyBas.reduce((a,b)=> (a[b.USER_ID] = (a[b.USER_ID] || 0) + parseFloat(b.BALANCE), a), {});
      //console.log(balanceInitialUsers);

      familyUsers.forEach(user=>{
        if(user.SHOW_IN_BALANCE_CHART === '0' || user.CENTRAL_BANK === '1') return;

        let runningTotal = 0;
        //console.log(user);
        yearMonths.forEach(yearMonth=>{
          //console.log(yearMonth);
          var key = user.ID + '-' + yearMonth;
          //console.log(key);
          if(yearMonth === '1900-01'){
            runningTotal = parseFloat(balanceInitialUsers[user.ID]);
          }else{
            if(totalByUserYearMonth[key]){
              runningTotal += totalByUserYearMonth[key]['amount'];
            }
          }

          balanceData.push({userId: user.ID, userName:user.FULL_NAME, yearMonth:yearMonth, runningTotal:runningTotal});

        })
      })

      //console.log(balanceData);



      const rows = balanceData;
      //console.log(rows);

      var rv = {};
      var datasets = [];
      var labels = [];
      var firstUserId = null;
      var userLabels = {};
      var thisUserId;
      var prevUserId = 0;
      var userIndex = 0;
      for (var j = 0; j < rows.length; ++j){
        var row = rows[j];
        thisUserId = row['userId'];
        if(thisUserId !== prevUserId) userIndex++;
        if(!rv[row['userId']]) rv[row['userId']] = {};

        rv[row['userId']]['userId'] = row['userId'];
        rv[row['userId']]['label'] = row['userName'];
        rv[row['userId']]['borderColor'] = colors[userIndex];
        rv[row['userId']]['backgroundColor'] = colors[userIndex];

        if(!rv[row['userId']]['data']) rv[row['userId']]['data'] = [];
        rv[row['userId']]['data'].push(row['runningTotal']);

        if(!userLabels[row['userId']]) userLabels[row['userId']] = [];
        userLabels[row['userId']].push(row['yearMonth'] === '1900-01' ? 'Now' : row['yearMonth']);

        if(!firstUserId) firstUserId = row['userId'];
        prevUserId = thisUserId;
      }
      //console.log(rv);
      //console.log(userLabels);

      for (const userId in rv) {
        //console.log(userId);
        datasets.push(rv[userId]);
      }
      //console.log(datasets);

      labels = userLabels[firstUserId];
      //console.log(labels);

      var chartData = {};

      chartData.labels = labels;
      chartData.datasets = datasets;
      chartData.options = {
        scales: {
          x: {
            type: 'category',
            ticks: {
              autoSkip: true,
              maxTicksLimit:60,
              maxRotation: 90,
              minRotation: 90,
              font: {
                size: 10
              },
              labelOffset: -5

            }
          },

        }
      };


      localStorage.setItem('balanceChartData', JSON.stringify(chartData));


      return chartData;

    },


    createFutureBalances(periodOptions, schedules, allUpcomingTrans = null){

      var futureBalances = {};

      if(!allUpcomingTrans){
        allUpcomingTrans = this.getAllUpcomingTrans(schedules);
      }

      let fp;
      for(fp of periodOptions){
        //console.log(fp);
        var d = new Date();
        d.setMonth(d.getMonth() + fp.months);
        var fd = this.formatDateYyyymmddLocal(d);

        //create sum by bas
        var resSumByBas = [];
        var trans = allUpcomingTrans.filter(tran=>tran.TRAN_DATE <= fd);

        trans.reduce(function(res, tran) {
          if (!res[tran.ACCOUNT_ID]) {
            res[tran.ACCOUNT_ID] = {baId: tran.ACCOUNT_ID, userId:tran.USER_ID, amount: 0};
            resSumByBas.push(res[tran.ACCOUNT_ID]);
          }
          res[tran.ACCOUNT_ID].amount += parseFloat(tran.AMOUNT);
          return res;
        }, {});


        futureBalances[fp.name] = resSumByBas;
      }

      localStorage.setItem('futureBalances', JSON.stringify(futureBalances));

      return futureBalances;
    },




    getAllUpcomingTrans(schedules, noCB = false, noParents = false, userId = null){
      //console.log('getAllUpcomingTrans run');

      var allUpcomingTrans = [];
      let schedule;
      let today = new Date();
      let todayYmd = this.formatDateYyyymmddLocal(today);
      var familyUsers = JSON.parse(localStorage.getItem('familyUsers'));

      //console.log(userId);

      for(schedule of schedules){

        if(userId && (schedule.USER_ID !== userId && schedule.USER_ID_RELATED !== userId )) continue;

        if(schedule.SCHEDULE){

          //console.log('------------------------------new schedule '+schedule.ID+' starts');
          //console.log(schedule);

          var rruleDb = JSON.parse(schedule.SCHEDULE);
          //console.log('rruleDb is ');
          //console.log(rruleDb);
          const rule = new RRule(this.createRruleOpt(rruleDb, schedule.NEXT_DATE));

// Get all occurrence dates (Date instances):
          //rule.all();
          var user = familyUsers.filter(user=>user.ID === schedule.USER_ID)[0];
          var userRelated = familyUsers.filter(user=>user.ID === schedule.USER_ID_RELATED)[0];



          var trans = [];

          if(!noCB || (noCB && user.CENTRAL_BANK === '0')){
            if(!noParents || (noParents && user.PARENT_ROLE === '0')){
              if(!userId || (userId && schedule.USER_ID === userId) ){
                rule.all().map(date=>
                    {
                      //return {'date':date.toLocaleDateString('en-CA', {timeZone: 'UTC'}), 'sId':schedule.ID, 'baId':schedule.ACCOUNT_ID, 'userId':schedule.USER_ID, 'amount':schedule.AMOUNT, 'tranDesc':schedule.DETAILS, 'currency':schedule.CURRENCY_CODE}
                      let thisSchedule = {...schedule};
                      //thisSchedule.TRAN_DATE = date.toLocaleDateString('en-CA', {timeZone: 'UTC'});
                      thisSchedule.TRAN_DATE = this.formatDateYyyymmddUtc(date);
                      delete thisSchedule.SCHEDULE;
                      trans.push(thisSchedule);
                    }
                );
              }
            }
          }



          var transRelated = [];
          if(!noCB || (noCB && userRelated.CENTRAL_BANK === '0')){
            if(!noParents || (noParents && userRelated.PARENT_ROLE === '0')) {
              if (!userId || (userId && schedule.USER_ID_RELATED === userId)) {
                rule.all().map(date=> {
                      //return {'date':date.toLocaleDateString('en-CA', {timeZone: 'UTC'}), 'sId':schedule.ID, 'baId':schedule.ACCOUNT_ID_RELATED, 'userId':schedule.USER_ID_RELATED, 'amount':-schedule.AMOUNT, 'tranDesc':schedule.DETAILS, 'currency':schedule.CURRENCY_CODE}
                      let thisSchedule = {...schedule};
                      //thisSchedule.TRAN_DATE = date.toLocaleDateString('en-CA', {timeZone: 'UTC'});
                      thisSchedule.TRAN_DATE = this.formatDateYyyymmddUtc(date);
                      thisSchedule.AMOUNT = -schedule.AMOUNT;
                      thisSchedule.USER_ID = schedule.USER_ID_RELATED;
                      thisSchedule.USER_ID_RELATED = schedule.USER_ID;
                      thisSchedule.ACCOUNT_ID = schedule.ACCOUNT_ID_RELATED;
                      thisSchedule.ACCOUNT_ID_RELATED = schedule.ACCOUNT_ID;
                      thisSchedule.ACCOUNT_NAME = schedule.ACCOUNT_NAME_RELATED;
                      thisSchedule.ACCOUNT_NAME_RELATED = schedule.ACCOUNT_NAME;

                      delete thisSchedule.SCHEDULE;
                      transRelated.push(thisSchedule);
                    }
                );
              }
            }
          }


          //console.log(trans);

// Get a slice:
          //rule.between(datetime(2012, 8, 1), datetime(2012, 9, 1));
          //console.log(rule.between(datetime(2012, 8, 1), datetime(2012, 9, 1)));

// Get an iCalendar RRULE string representation:
// The output can be used with RRule.fromString().

          //rule.toString();
          //console.log(rule.toString());
// Get a human-friendly text representation:
// The output can be used with RRule.fromText().

          //rule.toText();
          //console.log(rule.toText());


          allUpcomingTrans.push(...trans);
          allUpcomingTrans.push(...transRelated);
        }


      }


      allUpcomingTrans.sort((a,b)=>a.TRAN_DATE.localeCompare(b.TRAN_DATE));
      //console.log(allUpcomingTrans);
      return allUpcomingTrans;

    },



    createRruleOpt(rruleDb, nextDate, forTxtOnly = false){


      var rruleObj = {};

      rruleObj.freq = RRule[rruleDb.freq];
      rruleObj.interval = rruleDb.interval;
      //rruleObj.dtstart = new Date(rruleDb.dtstart);
      if(rruleDb.dtstart > nextDate || !nextDate){
        //rruleObj.dtstart = new Date(rruleDb.dtstart);
        let d = new Date(rruleDb.dtstart);
        d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
        rruleObj.dtstart =  d;

      }else{
        rruleObj.dtstart = new Date(nextDate);
      }

      if(rruleDb.freq === 'WEEKLY'){
        let weekday;
        rruleObj.byweekday = [];
        for(weekday of rruleDb.byweekday){
          rruleObj.byweekday.push(RRule[weekday]);
        }

      }

      if(rruleDb.freq === 'MONTHLY' || rruleDb.freq === 'YEARLY'){
        if(rruleDb.bymonthday){
          rruleObj.bymonthday = rruleDb.bymonthday
        }

        if(rruleDb.byweekday){
          rruleObj.byweekday = RRule[rruleDb.byweekday];
          rruleObj.bysetpos = rruleDb.bysetpos;
        }

        if(rruleDb.bysetpos){
          rruleObj.bysetpos = rruleDb.bysetpos;
        }
      }



      if(rruleDb.freq === 'YEARLY'){
        //console.log(this.bymonth);
        rruleObj.bymonth = rruleDb.bymonth;

      }

      if(rruleDb.freq === 'never'){
        rruleObj.freq = RRule.DAILY;
        rruleObj.count = 1;
      }



      if(rruleDb.endOption && rruleDb.endOption === 'until'){
        rruleObj.until = new Date(rruleDb.until);
      }else{
        if(rruleDb.until){
          rruleObj.until = new Date(rruleDb.until);
        }else{
          if(!forTxtOnly){
            //if until not set, make it 5 years from now
            var d = new Date();
            d.setMonth(d.getMonth() + 60);
            //var fd = d.toLocaleDateString('en-CA');
            var fd = this.formatDateYyyymmddUtc(d);
            rruleObj.until = new Date(fd);
          }

        }
      }

      if(rruleDb.endOption && rruleDb.endOption === 'count'){
        rruleObj.count = rruleDb.count;
      }else{
        if(rruleDb.count){
          rruleObj.count = rruleDb.count;
        }else{
          if(!forTxtOnly) {
            rruleObj.count = 2000;
          }
        }
      }
      //console.log('rruleObj is');
      //console.log(rruleObj);

      return rruleObj;

    },

    formatDateYyyymmddLocal(d){
      const year = d.getFullYear();
      const month = `0${d.getMonth() + 1}`.slice(-2);
      const day = `0${d.getDate()}`.slice(-2);

      return `${year}-${month}-${day}`;
    },

    formatDateYyyymmddUtc(d){
      d.setMinutes(d.getMinutes() + this.$root.timezoneOffset);
      return  this.formatDateYyyymmddLocal(d);
    },



  },
  created: function () {


    axios.interceptors.response.use(undefined, function (err) {
        return new Promise(function (resolve, reject) {
          if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
            this.$store.dispatch('auth/logout');
          }
          throw err;
        });
      });

    window.addEventListener('touchstart', (e) => {
      this.touchstartX = e.changedTouches[0].screenX;
      this.touchstartY = e.changedTouches[0].screenY;
    }, false);

    window.addEventListener('touchend', (e) => {
      this.touchendX = e.changedTouches[0].screenX;
      this.touchendY = e.changedTouches[0].screenY;
      this.handleGesture();
    }, false);

    CapacitorApp.addListener('backButton', ({canGoBack}) => {
      if(!canGoBack){
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });

  },
  mounted() {
    //console.log(navigator.userAgent);
    this.localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    var d = new Date();
    this.timezoneOffset = d.getTimezoneOffset();



  }


}
</script>



<style>


html, body {
  width: auto!important;
  overflow-x: hidden!important;
}

/*
body {
  background: url(/images/bg.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
*/



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
  margin-bottom:200px;
}

.container{
  max-width:800px !important;
}

#nav {
  display: flex;
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: #efefef;
  z-index:1000;
}

#nav a {
  font-size: 12px;
  color: #2c3e50;
  width:50%;
  text-align: center;
  margin: 0;
  padding: 10px 0;
  text-decoration: none;
}

#nav a.router-link-exact-active, #nav a.router-link-active {
  color: #f9f9f9;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

#nav svg{
  font-size:15px;
}

ul {
  padding: 0;
  list-style:none;
}

ul{
  padding-left:0 !important;
}

.container{
  margin-top:80px;
}

.pageTitle{
  font-size:20px;
  padding-left:20px;
}

#topbar{
  position: fixed;
  top: 0;
  height: 50px;
  width: 100%;
  justify-content: center;
  align-items: center;
  /*background-color: #ffd603;*/
  background-color: #0dcaf0;
  z-index:10;

}



.backBtn{
  width:40px;
  text-align: center;
  padding:5px;
  border-radius: 5px;
  margin: 0 10px;
}

.backBtn:hover{
  background-color: #0dcaf0;
}

.msg{
  margin:50px 10px 10px 10px;
  padding:10px;
}

.alink{
  color: #06b0f3;
  text-decoration: underline;
}

.clickable:hover{
  cursor:pointer;
  filter: brightness(97%);
}

.negative{
  color:red;
}

.positive{
  color:green;
}

.fa-toggle-on{
  color:green;
}

.hidden{
  display:none;
}

select option{
  font-size:12px;
  max-width: 100%;
}

.d-flex-center{
  justify-content: center;
  align-items: center;
}

.flex-left{
  display:flex;
  justify-content: left;
  align-items: center;
}



</style>
