<template>
  <a v-if="isExternal" :href="to" >
    <slot/>
  </a>
  <router-link v-else v-bind="$props" class="nav-tab" :class="{'router-link-active': subIsActive}" @click="this.$root.playClickSound();"><slot/></router-link>
</template>


<script>
  import {RouterLink} from 'vue-router'

  export default {
    props: {
      ...RouterLink.props
    },

    computed:{
      isExternal(){
        return typeof this.to === 'string' && this.to.startsWith('http')
      },
      subIsActive() {
        return this.$route.path.indexOf(this.to) === 0
      }
    }
  }


</script>


<style>

</style>
