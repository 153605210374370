export const colors = [

    'rgba(255, 99, 132,0.5)',
    'rgba(255, 205, 86,0.5)',
    'rgba(75, 192, 192,0.5)',
    'rgba(255, 159, 64,0.5)',
    'rgba(54, 162, 235,0.5)',
    'rgba(153, 102, 255,0.5)',
    'rgba(201, 203, 207,0.5)',
    'rgba(0,130,200,0.5)',
    'rgba(60,180,75,0.5)',

    'rgba(145,30,180,0.5)',
    'rgba(70,240,240,0.5)',
    'rgba(210,245,60,0.5)',
    'rgba(245,130,48,0.5)',
    'rgba(0,128,128,0.5)',
    'rgba(230,190,255,0.5)',
    'rgba(170,110,40,0.5)',
    'rgba(128,0,0,0.5)',
    'rgba(170,255,195,0.5)',
    'rgba(128,128,0,0.5)',
    'rgba(0,0,128,0.5)',
    'rgba(128,128,128,0.5)',
    'rgba(230,25,75,0.5)',
    'rgba(250,190,190,0.5)',
    'rgba(255,215,180,0.5)',
    'rgba(240,50,230,0.5)',
    'rgba(255,225,25,0.5)'

    ]