<template>
  <div class="container">
    <div style="display:flex;">
      <BackButton :path="{ path: '/settings'}" />
      <h5 style="margin:auto;">{{userName}}'s Bank Accounts</h5>
    </div>
    <hr>
    <div class="row">
      <div class="">
        <ul class="list2">
          <li class="girl account flex clickable" v-for="(account,idx) in accounts" :key="account.BA_ID"  @click="toggle(idx, account.BA_ID)" :class="{'active': idx === activeIndex}" >
            <div class="avatar"><img :src="account.AVATAR_URL"></div>
            <div><div class="accountName">{{account.ACCOUNT_NAME}} ({{account.ACCOUNT_TYPE}})</div><div class="balance">$ {{vueNumberFormat(account.BALANCE)}}</div></div>
            <div class="selectDiv"><font-awesome-icon :icon="['fas', 'circle']" class="selectSpot"/></div>
          </li>
        </ul>
      </div>
    </div>

    <div id="actionBtnsContainer">
      <div id="actionBtnsDiv">
        <div class="edit userAction actionDiv hiddenAction clickable" v-if="this.activeBankAccountId" @click="goToBankAccountEdit"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'pencil-alt']"/></div><div class="actionTxt">Edit</div></div>
        <div class="delete userAction actionDiv hiddenAction clickable" v-if="this.activeBankAccountId && this.accounts[this.activeIndex].CENTRAL_BANK !== '1'" @click="showDeleteModal=true"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'trash']"/></div><div class="actionTxt">Delete</div></div>
        <div class="add userAction actionDiv clickable" @click="goToBankAccountAdd"><div class="actionBtn"><font-awesome-icon :icon="['fas', 'plus']"/></div><div class="actionTxt">Add Account</div></div>
      </div>
    </div>

  </div>



  <div class="modalParent">
    <vue-final-modal v-model="showDeleteModal" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="">Are you sure you want to delete this bank account?</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn delConfirmBtn delYes clickable" @click="delBankAccount"><font-awesome-icon :icon="['fas', 'trash']"/> Yes</div>
          <div class="btn delConfirmBtn delNo clickable" @click="showDeleteModal=false"><font-awesome-icon :icon="['fas', 'times']"/> No</div>
        </div>
      </div>
    </vue-final-modal>
  </div>

  <div class="modalParent">
    <vue-final-modal v-model="showUserAccountLimit" classes="modal-container" content-class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">There is a limit of {{ this.$config.singleUserAccountLimit }} accounts for each user.</h5>
      </div>

      <div class="modal-body">
        <div style="display:flex;justify-content: center;">
          <div class="btn clickable" @click="showUserAccountLimit=false">Close</div>
        </div>
      </div>
    </vue-final-modal>
  </div>


</template>

<script>
import {VueFinalModal } from 'vue-final-modal';
import BackButton from "@/components/BackButton";

export default {
  name: 'BankAccounts',
  components: {
    BackButton,
    VueFinalModal
  },
  data(){
    return{
      accounts:null,
      userName:'',
      activeIndex: null,
      activeBankAccountId: null,
      showDeleteModal:false,
      showUserAccountLimit:false,
      userId:null,
      userInfo:{},
      isAdmin:'0',
      userAccountCount:0
    }
  },
  methods:{
    getUserBankAccounts: function(){
      let loader = this.$loading.show();
      this.axios
          .post(this.$hostApi,{task:'getUserBankAccounts', userId:this.userId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{

            const balances = response.data.balance;
            const accounts = [];
            for (const userId in balances) {
              if (Object.prototype.hasOwnProperty.call(balances, userId)) {
                const userAccounts = balances[userId];
                this.userName = userAccounts[0].FULL_NAME;
                for (const accountKey in userAccounts) {
                  if (Object.prototype.hasOwnProperty.call(userAccounts, accountKey)) {
                    accounts.push(userAccounts[accountKey]);
                  }
                }
              }
            }
            this.accounts = accounts;
            this.userAccountCount = this.accounts.length;
            //console.log(accounts);
            loader.hide();
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("There was an error!", error);
          })
    },
    toggle: function(index, baId){
      this.$root.playClickSound();
      this.activeIndex = index;
      this.activeBankAccountId = baId;

    },
    goToBankAccountAdd:function(){
      console.log(this.$config.singleUserAccountLimit);
      if(this.userAccountCount === this.$config.singleUserAccountLimit){
        this.showUserAccountLimit=true;
      }else{
        this.$root.gotoRoute({ path: '/settings/bas/ba', query: { userid: this.userId, userName: this.userName}})
      }


    },
    goToBankAccountEdit:function(){
      this.$root.gotoRoute({ path: '/settings/bas/ba', query: { userid: this.userId,  baId:this.activeBankAccountId, userName: this.userName}})
    },

    delBankAccount:function(){
      this.axios
          .post(this.$hostApi,{task:'delBankAccount', userId:this.userId, baId:this.activeBankAccountId},{
            headers:{
              'token':localStorage.getItem('user')
            }
          })
          .then(response =>{
            this.activeIndex = null;
            this.activeBankAccountId = null;
            this.showDeleteModal = false;
            this.getUserBankAccounts(this.userId);
          })
          .catch(error => {
            this.errorMessage = error.message;
            console.error("error deleting user!", error);
          })
    }
  },
  created() {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userInfo = userInfo;
    this.isAdmin = userInfo && userInfo.FAMILY_ADMIN ? userInfo.FAMILY_ADMIN : '0';

  },
  mounted() {
    document.title = "User Bank Accounts";
    this.userId = this.$route.query.userid;
    if(!this.userId) this.userId = this.userInfo.ID;
    this.getUserBankAccounts(this.userId);
  }
}
</script>

<style scoped>

.list2 li{

  margin:3px 5px;

  align-items: center;
  border:2px solid #fff;
}

.list2 .avatar img{
  width:40px;
  border:2px solid #9c9c9c;
  border-radius: 50%;
  padding:3px;
}

.list2 li.active{
  border:2px solid #0e90d2;
}

.list2 li{
  position:relative;
  background-color: #f5f5f5;
}

.email{
  font-size:12px;
}




.selectDiv{
  position:absolute;
  right:10px;
  width:50px;
}

.selectSpot{
  color:#fff;
}

li.active .selectSpot{
  color: #0e90d2;
}

.balance{
  font-size:12px;
}


.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

#actionBtnsContainer {
  position: fixed;
  bottom: 100px;
  right: 50px;
  height: 45px;
  color: #fff;
}

#actionBtnsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionDiv {
  margin-left: 10px;

}

.actionBtn {
  width: 45px;
  height: 45px;
  background-color: #0dcaf0;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  text-align: center;
  display:flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
}

.actionTxt {
  color: #3b3b3b;
  font-size: 8px;
  height: 10px;
  text-align: center;
}

.modal-dialog.fullwidth {
  width: 100% !important;
  max-width: 100% !important;
}

.delYes {
  background-color: red;
  color: #fff;
}

.delConfirmBtn {
  text-align: center;
  border: 1px solid #f5f5f5;
  margin: 10px;
}

.delNo {
  background-color: #e4e4e4;
  color: #3b3b3b;
}

.delConfirmBtn svg {
  margin-right: 10px;
}

.flex {
  display: flex;
  justify-content: left;
  align-items: center;
}

.avatar {
  width: 60px;
  margin: 5px 10px;
}



/*modal*/
.modalParent :deep(.modal-container) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modalParent :deep(.modal-content){
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem !important;
  padding: 1rem !important;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  width:unset;

}
.modal__title {
  margin: 0 2rem 0 0;
  font-size: 14px;
  font-weight: 700;
}
.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  border: 0 solid #e2e8f0;
}

.modal-header .close {
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5;
}

</style>