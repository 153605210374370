//https://jacklyons.me/blog/how-to-fix-vuejs-not-working-in-ie11/


import { createApp } from 'vue'
import axios from 'axios'
import router from './router/router.js'
import store from './store/store.js'
import App from './App.vue'
//import VueCarousel from 'vue-carousel';
//import { SetupCalendar } from 'v-calendar';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import AppLink from "@/components/AppLink.vue"
import config from './services/config';
import BackButton from "@/components/BackButton";
import clickSound from '@/assets/tennis.mp3'
import loginSound from '@/assets/dingup.mp3'
import logoutSound from '@/assets/dingdown.mp3'



//https://www.npmjs.com/package/@capacitor/app
//import { App as CapacitorApp } from '@capacitor/app';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import VueNumberFormat from 'vue-number-format'
import { FontAwesomeIcon } from './plugins/fontawesome'
//import TextClamp from 'vue3-text-clamp';



const app = createApp(App)
    .component('AppLink',AppLink)
    .use(router)
    .use(store)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(VueNumberFormat, {prefix: '', decimal: '.', thousand: ','})
    .use(VueLoading,{color:'rgb(0 171 0)'})
    //.use(SetupCalendar, {})
        .component('back-button',BackButton)
    //.use(vueCountryRegionSelect)
    //.use(CapacitorApp)
    ;

app.config.globalProperties.axios=axios;
app.config.globalProperties.$hostname = config.hostname
app.config.globalProperties.$hostApi = config.hostApi
app.config.globalProperties.$config = config;
app.config.globalProperties.token = localStorage.getItem('user');
app.config.globalProperties.$clickSound = new Audio(clickSound);
app.config.globalProperties.$loginSound = new Audio(loginSound);
app.config.globalProperties.$logoutSound = new Audio(logoutSound);

app.mount('#app');